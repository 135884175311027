import ObraDetalle from '../Obra/ObraDetalle';

import { useParams } from 'react-router-dom';
import { getObra } from "../../services/firebase/firestore";
import { useFirestore } from '../../hooks/useFirestore';


const ObraDetalleContainer = () => {
    
    const { obraId } = useParams()
    const { obra } = useFirestore(() => getObra(obraId))
        
    return (
        <section>
            <ObraDetalle {...obra}/>
        </section>
    )
}

export default ObraDetalleContainer