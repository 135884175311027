import React, { useState, useRef } from 'react';
import { setDoc, doc } from 'firebase/firestore';
import { db } from '../../services/firebase';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import emailjs from '@emailjs/browser';
import SignatureCanvas from 'react-signature-canvas';

import swal from 'sweetalert';

const Obra = () => {
    
    const form = useRef();
    const [obra, setObra] = useState({
        date: new Date().toLocaleDateString('es-AR'),
        nombre: '',
        dni: '',
        unidadfuncional: '',
        nrocasa: '',
        firma: '',
    });

    const signRef = useRef();
    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();

    const handleClear = () => {
        signRef.current.clear();
        setValue('firma', ''); // Limpiar el valor del campo firma en el formulario
        setObra({ ...obra, firma: '' });
    };

    const handleGenerate = () => {
        if (signRef.current.isEmpty()) {
        swal('¡Ups!', 'La firma es obligatoria', 'error');
        } else {
        const dataURL = signRef.current.getTrimmedCanvas().toDataURL('image/png');
        setValue('firma', dataURL); // Asignar la imagen de la firma al campo firma en el formulario
        setObra({ ...obra, firma: dataURL });
        }
    };

    const sendEmail = () => {
        emailjs
        .sendForm('gmail', 'mail-contacto', form.current, 'ywpL1dRWg4rq2UiEr')
        .then((result) => {
            console.log(result.text);
        })
        .catch((error) => {
            console.log(error.text);
        });
    };

    const createObra = () => {
        if (!obra.firma) {
        swal('¡Ups!', 'La firma es obligatoria', 'error');
        return;
        }

        setDoc(doc(db, 'obra', obra.dni), obra)
        .then(() => {
            swal('GRACIAS', `Recibimos el documento con tu autorización`, 'success');
            sendEmail();
            navigate('/');
        })
        .catch((error) => {
            console.log(error);
            swal('¡Ups!', `Algo falló`, 'error');
        });
    };

    const currentDate = new Date().toLocaleDateString('es-AR');

    return (
        <section className="obra">
            <div className="container-obra">
                <h2>AUTORIZACIÓN OBRA</h2>
                <form ref={form} onSubmit={handleSubmit(createObra)}>
                    <article>
                        Acassuso, San Isidro, Buenos Aires, {currentDate} 
                    </article>
                    <article>
                    Por medio del presente, y en este acto, yo,
                    <input 
                            className="input-form"
                            type="text" 
                            name="nombre" 
                            placeholder="Nombre y apellido legal"
                            value={obra.nombre}
                            {...register("nombre", { required: "El campo NOMBRE es obligatorio" })}
                            onChange={(e) => setObra({...obra, nombre: e.target.value})}
                        />
                    <ErrorMessage
                        errors={errors}
                        name="nombre"
                        render={({ message }) => <p>{message}</p>}
                    />
                    DNI N°
                    <input 
                        className="input-form"
                        type="text" 
                        name="dni" 
                        value={obra.dni}
                        placeholder="DNI"
                        {...register("dni", { required: "eL campo DNI es obligatorio" })}
                        onChange={(e) => setObra({...obra, dni: e.target.value})}
                    />
                    <ErrorMessage
                        errors={errors}
                        name="dni"
                        render={({ message }) => <p>{message}</p>}
                    />
                    propietaria de la unidad funcional número
                    <input 
                        className="input-form"
                        type="text" 
                        name="unidadfuncional" 
                        value={obra.unidadfuncional}
                        placeholder="Número unidad funcional"
                        {...register("unidadfuncional", { required: "El campo UNIDAD FUNCIONAL es obligatorio" })}
                        onChange={(e) => setObra({...obra, unidadfuncional: e.target.value})}
                    />
                    <ErrorMessage
                        errors={errors}
                        name="unidadfuncional"
                        render={({ message }) => <p>{message}</p>}
                    />
                    del inmueble de la calle Belisario Roldán
                    <input 
                        className="input-form"
                        type="text" 
                        name="nrocasa" 
                        value={obra.nrocasa}
                        placeholder="Número casa"
                        {...register("nrocasa", { required: "El campo NÚMERO CASA es obligatorio" })}
                        onChange={(e) => setObra({...obra, nrocasa: e.target.value})}
                    />
                    <ErrorMessage
                        errors={errors}
                        name="nrocasa"
                        render={({ message }) => <p>{message}</p>}
                    />
                    Acassuso, partido de San Isidro, autorizo a Raúl Agustín Scarone, propietario de la unidad funcional nro 2, a realizar obras en su propiedad tales como, entre otras:
                    <br/><br/>
                    -	Arreglos en general de las instalaciones complementarias del inmueble (agua, cloaca, electricidad, entre otros).
                    <br/>
                    -	Cambiar el material de cubierta de techo. 
                    <br/>
                    -	Cambiar revestimiento interior y/o exterior, ejecutar cielorrasos. 
                    <br/>
                    -	Ejecutar solados dentro del predio.
                    
                    <br/><br/>
                    Declaro que la firma inserta en el presente formulario digital enviado por Raúl Agustín Scarone pertenece a mi puño y letra. Conste

                    </article>

                    <SignatureCanvas
                        canvasProps={{ className: 'sigCanvas' }}
                        ref={signRef}
                        clearOnResize={false}
                        onEnd={handleGenerate}
                    />

            <div className="container-btn-canvas">
                <button type="button" onClick={handleClear} className="btnN">
                BORRAR FIRMA
                </button>
            </div>

            {errors.firma && <ErrorMessage errors={errors} name="firma" render={({ message }) => <p>{message}</p>} />}

            {/* <article>En prueba de conformidad, firman médico y paciente al pie del presente. Conste.</article> */}
            {obra.firma ? <input type="submit" value="ENVIAR" className="btnN" /> : null}
            </form>
        </div>
        </section>
    );
};

export default Obra;