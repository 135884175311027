import React, { useRef } from "react";
import { useReactToPrint } from 'react-to-print';


const ObraDetalle = (
    {
        date,
        nombre,
        dni,
        unidadfuncional,
        nrocasa,
        firma,
    }
) => {

    const componentPDF = useRef();

    const generatePDF = useReactToPrint({
        content: ()=> componentPDF.current,
        documentTitle: `Autorizacion-${nombre}-${dni}`,
        onafterprint:()=>alert("Autorización descargada")
    })

    return(
        <section className="obra-detalle">
            <div ref={componentPDF}>
                <div className="container-obra-detalle">
                <table>
                    <tr>
                        <td className="w100">
                            Acassuso, San Isidro, Buenos Aires, {date} 
                            <br/><br/>
                            Por medio del presente, y en este acto, yo, <span>{nombre}</span>, DNI N° <span>{dni}</span>,                     
                            propietaria de la unidad funcional número <span>{unidadfuncional}</span> del inmueble de la calle Belisario Roldán <span>{nrocasa}</span>,  
                            Acassuso, partido de San Isidro, autorizo a Raúl Agustín Scarone, propietario de la unidad funcional nro 2, a realizar obras en su propiedad tales como, entre otras:
                            <br/><br/>
                            -	Arreglos en general de las instalaciones complementarias del inmueble (agua, cloaca, electricidad, entre otros).
                            <br/>
                            -	Cambiar el material de cubierta de techo. 
                            <br/>
                            -	Cambiar revestimiento interior y/o exterior, ejecutar cielorrasos. 
                            <br/>
                            -	Ejecutar solados dentro del predio.

                            <br/><br/>

                            Declaro que la firma inserta en el presente formulario digital enviado por Raúl Agustín Scarone pertenece a mi puño y letra. Conste
                        </td>
                    </tr>
                </table>

                <table>
                    <tr>
                        <td className="w25">
                            <img src={firma} />
                            <br/>
                            FIRMA
                            <br/><br/>
                            {nombre}
                            <br/>
                            ACLARACIÓN
                            <br/><br/>
                            {dni}
                            <br/>
                            DNI
                        </td>
                    </tr>
                </table>              
                </div>
            </div>
            <button className="btnN" style={{margin:"50px"}} onClick={ generatePDF }>
                DESCARGAR AUTORIZACIÓN
            </button>

        </section>
    )
}

export default ObraDetalle